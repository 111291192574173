import { format, parseISO } from 'date-fns';
import { Country, State } from 'country-state-city';
import { get } from 'lodash';
import {
  REGIONS,
  REGIONS_HOSTNAMES,
  STATES_PROVINCES_CODES,
} from '../constants';

export const CURRENT_YEAR = new Date().getFullYear();

export const YEARS = Array(...Array(CURRENT_YEAR - 2005)).map((_, i) => ({
  value: `${CURRENT_YEAR - i}`,
  label: `${CURRENT_YEAR - i}`,
}));

export const getRandomID = () => {
  return Math.random().toString(36).substring(7);
};

export const getUserRegion = (user = {}) => {
  if (user?.region) {
    return REGIONS[user.region] || REGIONS.mrets;
  }

  return getRegionByDomain();
};

export const getRegionByDomain = (location = window.location) => {
  const { host } = location;
  let selectedRegion = null;
  const regionsWithHosts = Object.keys(REGIONS_HOSTNAMES);

  for (let i = 0; i < regionsWithHosts.length; i += 1) {
    if (!selectedRegion) {
      const hostMatch = REGIONS_HOSTNAMES[regionsWithHosts[i]].find(
        (hostname) => host.includes(hostname),
      );

      if (hostMatch) {
        selectedRegion = regionsWithHosts[i];
        break;
      }
    }
  }

  return selectedRegion || REGIONS.mrets;
};

export const getDaysToGo = (startDate, endDate) => {
  const daysToGoValue = Math.ceil(
    (endDate - startDate) / (1000 * 60 * 60 * 24),
  );

  return daysToGoValue;
};

export const getCountryByCode = (isoCode) => {
  const { name: label } = Country.getCountryByCode(isoCode);

  return { isoCode, label };
};

export const getStateByCode = (countryISOCode, stateISOCode) => {
  let label = null;

  try {
    const { name } = State.getStateByCodeAndCountry(
      stateISOCode,
      countryISOCode,
    );
    label = name;
  } catch (e) {
    // do nothing
  }

  return { isoCode: stateISOCode, label, countryISOCode, found: !!label };
};

export function compareStates(a, b) {
  const newA = a.country?.name?.toUpperCase() || a.name.toUpperCase();
  const newB = b.country?.name?.toUpperCase() || b.name.toUpperCase();
  if (newA < newB) {
    return -1;
  }
  if (newA > newB) {
    return 1;
  }
  return 0;
}

export const getCountries = () => {
  return Country.getAllCountries();
};

export const getCountryStates = ({ isoCode: countryCode }) => {
  const states = State.getStatesOfCountry(countryCode);

  return states;
};

export const fetchCountries = () => {
  return getCountries();
};

export const fetchCountryStates = (countryCode, countries) => {
  const countryObj = countries.find(({ isoCode }) => isoCode === countryCode);

  if (countryObj) {
    const countryStates = getCountryStates(countryObj);
    return countryStates.map((cs) => ({ ...cs, country: countryObj }));
  }

  return [];
};

export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const stringToDate = (stringDate) => {
  return parseISO(stringDate);
};

export const formatDateString = (dateString, dateFormat, fallback = 'N/A') => {
  let formattedDate = fallback;

  if (dateString) {
    formattedDate = stringToDate(
      dateString.endsWith('Z') ? dateString.slice(0, -1) : dateString,
    );

    formattedDate = format(formattedDate, dateFormat);
  }

  return formattedDate;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

export const findLocationByCode = (object, locationCodePath) => {
  const id = get(object, locationCodePath);
  const newObject = {
    id,
    name: id
      ? capitalizeFirstLetter(getKeyByValue(STATES_PROVINCES_CODES, id))
      : null,
  };
  return newObject;
};

export const buildMarketCertificateStatus = (object) => {
  let newStatus = object.status;
  if (object.status === 'active') {
    newStatus = 'unencumbered';
  }
  return {
    id: object.status,
    name: capitalizeFirstLetter(newStatus),
  };
};

export const set = (obj, path, value) => {
  const pathArray = path.split('.');
  const pathArrayLength = pathArray.length;

  const objCopy = JSON.parse(JSON.stringify(obj));

  if (pathArrayLength === 1) {
    objCopy[path] = value;
  } else {
    const currentPathKey = pathArray[0];

    objCopy[currentPathKey] = set(
      objCopy[currentPathKey] || {},
      pathArray.slice(1).join('.'),
      value,
    );
  }

  return objCopy;
};

export const parseDateToString = (
  date,
  dateFormat = "yyyy-MM-dd'T'HH:mm:ss",
) => {
  let newDate = date;
  if (date instanceof Date) {
    newDate = format(date, dateFormat);
  }
  return newDate;
};

export const getMonthFromInteger = (monthInteger) => {
  const fixedMonth = monthInteger < 10 ? `0${monthInteger}` : monthInteger;

  const month = formatDateString(`2021-${fixedMonth}-25`, 'LLLL');

  return month;
};

export const getErrorMessageFromResponse = (
  errorResponse,
  fallbackMessage = 'Something went wrong',
) => {
  let error;
  const errors = errorResponse?.response?.data?.errors;

  if (Array.isArray(errors) && errors?.length > 0) {
    [error] = errors;
  } else if (typeof errors === 'string') {
    error = { detail: errors };
  } else if (errors?.title) {
    error = { detail: errors.title };
  }

  return error?.title || error?.detail || fallbackMessage;
};

export const formatNumberWithCommas = (number, truncate = 5) => {
  const fixedNumber = Number(number || 0);
  const intNumber = parseInt(fixedNumber, 10);
  const decimals = fixedNumber.toString().split('.')[1];

  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = '$1,';

  let formattedNumber = intNumber.toString().replace(exp, rep);

  if (decimals) {
    const truncatedDecimals = decimals.toString().slice(0, truncate);
    formattedNumber = `${formattedNumber}.${truncatedDecimals}`;
  }

  return formattedNumber;
};

export const dateToUTC = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};

export const addDaysToDate = (date, days) => {
  const newDate = new Date();
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const renameObjectKeysToCamelCase = (object) => {
  const newObject = object;
  const keys = Object.keys(object);

  keys.forEach((key) => {
    const newKey = key.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', '');
    });

    if (!newObject[newKey]) {
      newObject[newKey] = object[key];
      delete newObject[key];
    }
  });

  return newObject;
};
